<template>
  <div class="container">
    <div class="model-selector">
      <select
        id="modelSelector"
        v-model="selectedModel"
        @change="onModelChange"
      >
        <option value="gpt-4o">ChatGPT-4o</option>
        <option value="claude-3-5-sonnet">Claude 3.5 Sonnet</option>
      </select>
    </div>
    <div class="chatbot-input">
      <div class="parent-div">
        <div class="message-area" ref="messageArea">
          <textarea
            v-model="userInput"
            @keydown.enter.prevent="handleEnterKey"
            @input="autoResize"
            spellcheck="false"
          ></textarea>
        </div>
        <div class="file-preview-area" v-if="attachedFiles.length > 0">
          <div class="file-preview-scroll">
            <div
              v-for="(file, index) in attachedFiles"
              :key="index"
              class="file-preview"
            >
              <button class="remove-file-btn" @click="removeFile(index)">
                <CIcon :icon="cilX" />
              </button>
              <img
                class="file-icon"
                v-if="isImageFile(file)"
                :src="getFilePreview(file)"
                :alt="file.name"
              />
              <div v-else class="file-icon">{{ getFileExtension(file) }}</div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="handleFileUpload"
        multiple
      />
      <CButton
        @click="openFileBrowser"
        class="file-upload-btn d-flex justify-content-center align-items-center"
      >
        <CIcon :icon="cilPaperclip" />
      </CButton>
    </div>
    <div class="system-prompt-container">
      <div class="system-prompt-label" @click="togglePromptCollapse">
        <span class="toggle-icon" :class="{ expanded: !isPromptCollapsed }"
          >▶</span
        >
        {{ getPromptLabel }}
      </div>
      <div v-if="!isPromptCollapsed" class="chatbot-input">
        <input
          id="system-prompt"
          type="text"
          v-model="promptValue"
          @input="autoResize"
          @keydown.enter.prevent="summarizeText"
          spellcheck="false"
        />
      </div>
    </div>
    <div class="generate-button-container">
      <button
        class="generate-button"
        @click="summarizeText"
        :disabled="
          isSummarizing || (!userInput && this.attachedFiles.length === 0)
        "
        :class="{ generating: isSummarizing }"
      >
        生成
      </button>
    </div>
    <div class="chatbot-result">
      <div
        class="result"
        ref="chatbotResult"
        v-html="parseMarkdown(summarizeResult)"
      ></div>
      <button
        class="pdf-button"
        @click="downloadPdf"
        :disabled="!summarizeResult || summarizeResult.trim() === ''"
      >
        PDF
      </button>
      <button
        class="copy-button"
        @click="copyContent"
        :disabled="!summarizeResult || summarizeResult.trim() === ''"
      >
        <CIcon :icon="cilCopy" />
      </button>
    </div>
    <!-- Overlay for processing message -->
    <div
      v-if="isCreatingChatbot || isUpdatingChatbot"
      class="processing-overlay"
    >
      <div class="processing-message">
        <div class="loading-icon-wrapper">
          <img
            src="../../../public/images/loading.gif"
            alt="Processing..."
            class="loading-icon"
          />
        </div>
        <div v-if="isCreatingChatbot">Creating chatbot! Please wait!</div>
        <div v-else-if="isUpdatingChatbot">Updating chatbot! Please wait!</div>
      </div>
    </div>
    <div v-if="showErrorPopup" class="popup">
      <div class="popup-content">
        <p>{{ errorMessage }}</p>
        <div class="popup-buttons">
          <button @click="cancel" class="btn btn-info white-text">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import StorageService from '../../services/StorageService.js'
import DOMPurify from 'isomorphic-dompurify'
import { marked } from 'marked'
import { cilSend, cilPaperclip, cilX, cilCopy } from '@coreui/icons'
export default {
  data() {
    return {
      cilSend,
      cilPaperclip,
      cilX,
      cilCopy,
      selectedModel: 'gpt-4o',
      userInput: '',
      attachedFiles: [],
      isPromptCollapsed: false,
      promptLabelText: '追加コンテキスト',
      promptValue: '',
      summarizeResult: '',
      botId: '',
      botName: 'POC Main Chat',
      isCreatingChatbot: false,
      isUpdatingChatbot: false,
      errorMessage: '',
      showErrorPopup: false,
      planerror: false,
      isSummarizing: false,
    }
  },
  async mounted() {
    try {
      console.log('get user..')

      const response = await HTTPService.getuser()
      this.user = response.data.user
      if (!this.user) {
        // If the user is not authenticated, push them to the login route
        this.$router.push('/login')
      }
    } catch (error) {
      console.error(error)
      // If there's an error or no user found, redirect to the login page
      this.$router.push('/login')
    }
  },
  computed: {
    getPromptLabel() {
      return this.promptLabelText
    },
  },
  methods: {
    onModelChange() {
      console.log('Selected model:', this.selectedModel)
    },

    handleFileUpload(event) {
      const files = Array.from(event.target.files)
      this.attachedFiles.push(...files)
      this.$refs.fileInput.value = ''
    },

    removeFile(index) {
      this.attachedFiles.splice(index, 1)
    },

    isImageFile(file) {
      return file.type.startsWith('image/')
    },

    getFilePreview(file) {
      return URL.createObjectURL(file)
    },

    getFileExtension(file) {
      return file.name.split('.').pop().toUpperCase()
    },

    openFileBrowser() {
      this.$refs.fileInput.click()
    },

    togglePromptCollapse() {
      this.isPromptCollapsed = !this.isPromptCollapsed
    },

    async summarizeText() {
      if (!this.userInput && this.attachedFiles.length === 0) return

      if (!this.botId) {
        if (this.attachedFiles.length > 0) {
          this.botName = `Uploaded ${this.attachedFiles[0].name}`
        } else {
          // Extract the first sentence or the first 20 characters, whichever is shorter
          const firstSentenceEnd =
            this.userInput.indexOf('.') !== -1
              ? this.userInput.indexOf('.') + 1
              : this.userInput.length
          const firstSentence = this.userInput.slice(0, firstSentenceEnd)

          // Limit to the first 20 characters if needed
          this.botName =
            firstSentence.length > 10
              ? firstSentence.slice(0, 20)
              : firstSentence
        }
      }

      // 1. Create or update bot with current files
      if (!this.botId || this.attachedFiles.length > 0) {
        await this.createChatbot()

        // Clear the attached files
        this.attachedFiles = []
      }

      const data = {
        bot_id: this.botId,
        user_input: this.userInput,
        prompt: this.promptValue,
        model: this.selectedModel,
      }
      this.summarizeResult = ''
      try {
        let token = StorageService.getToken()
        this.isSummarizing = true
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/summarize-text`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              Accept: 'text/event-stream',
            },
            body: JSON.stringify(data),
          },
        )

        if (!response.ok) {
          const errorBody = await response.json()
          this.errorMessage =
            errorBody.error ||
            `HTTP error! status: ${response.status} ${response.statusText}`
          this.showErrorPopup = true
          throw new Error(this.errorMessage)
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          const chunk = decoder.decode(value, { stream: true })
          const lines = chunk.split('\n').filter(Boolean)

          for (const line of lines) {
            if (line.startsWith('data:')) {
              const data = line.slice(5)
              if (data === '[DONE]') {
                // Streaming is complete
                console.log('Streaming complete')
              } else {
                this.summarizeResult += `${data.slice(1)}`
                this.$refs.chatbotResult.scrollTop =
                  this.$refs.chatbotResult.scrollHeight
              }
            }
          }
        }
      } catch (error) {
        console.error(`exception: ${error}`)
        if (!this.showErrorPopup) {
          this.errorMessage = error.message || 'An unknown error occurred'
          this.showErrorPopup = true
        }
      } finally {
        this.isSummarizing = false
      }
    },

    async createChatbot() {
      const formData = new FormData()
      if (this.attachedFiles) {
        for (let i = 0; i < this.attachedFiles.length; i++) {
          formData.append('files[]', this.attachedFiles[i])
        }
      }
      if (this.botName) {
        formData.append('data', JSON.stringify({ bot_name: this.botName }))
      }
      if (this.botId) {
        formData.append('bot_id', this.botId)
      }

      if (this.botId) this.isUpdatingChatbot = true
      else this.isCreatingChatbot = true
      await HTTPService.createChatbot(formData)
        .then((response) => {
          this.showConfirmation = true
          let botId = response.data.bot_id
          this.botId = botId
        })
        .catch((error) => {
          console.error(error)
          if (error.response && error.response.data.error) {
            this.errorMessage = error.response.data.error
            this.showErrorPopup = true
            if (
              error.response &&
              (error.response.status === 423 || error.response.status === 424)
            ) {
              this.planerror = true
            }
          } else {
            this.errorMessage = 'Failed to create chatbot: ' + error.response
            this.showErrorPopup = true
          }
        })
        .finally(() => {
          this.isCreatingChatbot = false
          this.isUpdatingChatbot = false
        })
    },

    cancel() {
      this.showErrorPopup = false
    },

    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        const renderer = new marked.Renderer()
        renderer.link = function (href, title, text) {
          // Renderer() seems to return different parameters depending on the version of marked installed...
          const link = {
            href: href.href ?? href,
            text: href.text ?? text,
          }
          return (
            '<a target="_blank" href="' + link.href + '">' + link.text + '</a>'
          )
        }

        text = text.replaceAll('```', '')
        let parsed = marked(text, { renderer: renderer })

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
            'target',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },

    handleEnterKey(event) {
      if (event.shiftKey) {
        // Allow Shift + Enter to create a new line
        const textarea = event.target
        const cursorPosition = textarea.selectionStart
        this.userInput =
          this.userInput.slice(0, cursorPosition) +
          '\n' +
          this.userInput.slice(cursorPosition)
        this.$nextTick(() => {
          textarea.selectionStart = cursorPosition + 1
          textarea.selectionEnd = cursorPosition + 1
        })
      } else {
        // Handle normal Enter keypress
        this.summarizeText()
      }
    },

    copyContent() {
      const range = document.createRange()
      range.selectNode(this.$refs.chatbotResult)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
    },

    downloadPdf() {
      const { jsPDF } = window.jspdf
      const doc = new jsPDF()
      const pageWidth = doc.internal.pageSize.getWidth()
      const margin = 10
      const textWidth = pageWidth - margin * 2
      const lines = doc.splitTextToSize(this.summarizeResult, textWidth)
      doc.text(lines, margin, 10)
      doc.save('summary.pdf')
    },
  },
}
</script>

<style scoped>
.container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 3px solid #001d61;
  border-radius: 10px;
  background-color: rgb(240, 245, 245);
}

.model-selector {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin: 5px 5px 15px 5px;
}

.model-selector select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 12px;
  height: 30px;
}

.chatbot-input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(218, 223, 227);
  border-radius: 5px;
  margin: 5px 5px 15px 5px;
  background-color: #fff;
}

input[type='text'] {
  flex: 1;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  line-height: normal;
}

.parent-div {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
}

.message-area textarea,
.chatbot-input input {
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  max-height: 150px;
  overflow-y: auto;
  border: none;
}

.message-area {
  height: 100%;
}

.message-area textarea {
  height: 100%;
  max-height: 400px;
}

textarea:focus,
input:focus {
  outline: none;
}

.file-preview-area {
  display: flex;
  align-items: flex-end;
  width: 100%; /* Makes it take full width of parent */
  height: 100%;
  margin: 5px;
  overflow-y: hidden;
  overflow-x: auto;
}

.file-preview-scroll {
  display: inline-flex; /* Changed to inline-flex */
  flex-wrap: nowrap;
}

.file-preview {
  flex: 0 0 auto; /* Prevents shrinking */
  margin-right: 10px;
  text-align: center;
  position: relative;
}

.file-preview img {
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.file-icon {
  width: 80px;
  height: 80px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 5px;
}

.remove-file-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 0x;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px; /* set the width */
  height: 20px; /* set the height to be the same as width */
  padding: 0; /* remove padding */
}

.remove-file-btn:hover {
  background-color: #ff1a1a;
}

.system-prompt-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.system-prompt-label {
  font-weight: bold;
  margin: 0px 0px 0px 5px;
  font-size: 14px;
  cursor: pointer;
}

.toggle-icon {
  display: inline-block;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.toggle-icon.expanded {
  transform: rotate(90deg);
}

.generate-button {
  padding: 10px 20px;
  background-color: #001d61;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.generate-button:hover .upload-button:hover {
  background-color: #002b8f;
}

.generate-button.generating {
  background-color: #808080; /* A lighter shade to indicate it's processing */
  cursor: not-allowed;
}

.chatbot-result {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(218, 223, 227);
  border-radius: 5px;
  margin: 40px 5px 5px 5px;
  overflow: hidden;
  position: relative;
}

.chatbot-result .result {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  resize: none;
  overflow-y: auto;
  background-color: #fff;
  color: inherit;
  font-family: inherit;
}

.chatbot-result .result:focus {
  outline: none;
}

.copy-button {
  bottom: 5px;
  right: 20px;
}

.pdf-button {
  bottom: 5px;
  right: 60px;
}

.copy-button,
.pdf-button {
  position: absolute;
  padding: 5px 10px;
  background-color: none;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:disabled,
.pdf-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.copy-button:hover,
.pdf-button:hover {
  background-color: #e0e0e0;
}

.file-upload-btn {
  width: 40px;
  height: 40px;
  background-color: #001d61;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
}

.file-upload-btn:hover {
  background-color: #002b8f;
}

.generate-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.loading-icon-wrapper {
  margin-bottom: 10px;
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px; /* adjust the width as needed */
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
