<template>
  <div class="chatbot">
    <h1>{{ name }}</h1>
    <div class="chatbot-container">
      <div class="chatbot-header" :style="getHeadingStyle">
        <div v-if="getImage" class="image-container">
          <span>
            <img :src="getImage" alt="chatbot-image" class="centered-image" />
          </span>
        </div>

        <div>
          <h2 class="chatbot-heading">
            {{ getHeading }}
          </h2>
          <p>{{ getSubHeading }}</p>
        </div>
      </div>

      <div class="chatbot-conversation" ref="chatbotConversation">
        <div
          class="chatbot-message"
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'user-message': message.is_user,
            'bot-message': !message.is_user,
          }"
        >
          <div v-if="message.is_user" :style="getUserMessageStyle">
            <strong>You:</strong> {{ message.text }}
          </div>

          <!-- <div
            v-else
            :style="getBotMessageStyle"
            class="prose prose-sm prose-chatgpt break-words max-w-6xl"
            v-html="parseMarkdown(message.text)"
          /> -->
          <div
            v-else
            :style="getBotMessageStyle"
            v-html="
              '<strong>' +
              getAssistantName +
              ':</strong>' +
              parseMarkdown(message.text)
            "
          ></div>
        </div>
      </div>
      <div class="chatbot-input">
        <input
          type="text"
          v-model="userInput"
          @keydown.enter="sendMessage"
          :placeholder="getInputMessage"
        />
        <CButton
          @click="sendMessage"
          class="d-flex justify-content-center"
          :style="getSendButtonStyle"
        >
          <CIcon :icon="cilSend" />
        </CButton>
      </div>
    </div>
    <div class="rag-results-table" v-if="ragResult && ragResult.length > 0">
      <h3>RAG Results</h3>
      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>RAG Result Text</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in ragResult" :key="index">
            <td>{{ result.source }}</td>
            <td>{{ result.content }}</td>
            <td>{{ result.score.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import { v4 as uuidv4 } from 'uuid'
import { cilSend } from '@coreui/icons'
import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'
// import hljs from 'highlight.js'

export default {
  props: {
    bot_id: {
      type: String,
      required: true,
    },
    conversation_id: {
      type: String,
      required: false,
    },
    style: {
      type: Object,
      default: () => ({
        botBackgroundColor: '#dcdcdc',
        botTextColor: 'black',
        chatbotHeading: 'Hello!',
        chatbotSubHeading: 'Ask me anything',
        firstMessage: 'Hello, what can I help you with?',
        headerBackgroundColor: '#0c2231',
        headerTextColor: '#FFFFFF',
        image: null,
        inputPlaceholderText: 'enter your question here..',
        sendButtonBackgroundColor: '#0c2231',
        userBackgroundColor: '#0c2231',
        userTextColor: '#FFFFFF',
        assistantName: 'Assistant',
      }),
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      messages: [],
      userInput: '',
      messageIndex: 0,
      conversationId: '',
      cilSend,
      localstyle: {},
      user: null,
      ragResult: [],
    }
  },
  async mounted() {
    console.log('loading..' + this.bot_id)

    try {
      console.log('get user..')

      const response = await HTTPService.getuser()
      this.user = response.data.user
    } catch (error) {
      console.error(error)
      this.$emit('change-active-component', {
        component: 'Login',
      })
    }

    marked.setOptions({
      silent: true,
      xhtml: true,
      breaks: true,
      gfm: true,
    })

    // const renderer = {
    //   code(code, lang) {
    //     let language = 'plaintext'
    //     let highlightedCode
    //     try {
    //       highlightedCode = hljs.highlightAuto(code).value
    //     } catch {
    //       language = hljs.getLanguage(lang) ? lang : 'plaintext'
    //       highlightedCode = hljs.highlight(code, { language }).value
    //     }

    //     // ... handle the highlighted code as needed

    //     // For example, you can append it to an element or return it as a string
    //     // For appending to an element:
    //     const container = document.createElement('div')
    //     container.innerHTML = highlightedCode
    //     document.body.appendChild(container)

    //     // For returning as a string:
    //     return highlightedCode
    //   },
    // }

    // marked.use({ renderer })

    if (this.bot_id !== 'preview') {
      try {
        const response = await HTTPService.get_style(this.bot_id)
        // console.log('finished loading bot settings')
        // console.log(response)
        this.localstyle = response.data
      } catch (error) {
        console.error(error)
        // if there is an error default
        this.localstyle = this.style
      }
    }

    if (this.bot_id === 'preview') {
      this.messages.push({
        text: this.style.firstMessage,
        is_user: false,
      })
      this.messageIndex = 1

      this.messages.push({
        text: 'what is this',
        is_user: true,
      })
      this.messageIndex = 2
    } else {
      this.messages.push({
        text: this.localstyle.firstMessage,
        is_user: false,
      })
      this.messageIndex = 1
    }
    if (this.conversation_id != '') {
      this.conversationId = this.conversation_id
    }

    if (this.$route.query.conversationId) {
      this.conversationId = this.$route.query.conversationId
    }

    if (!this.conversationId) {
      this.conversationId = uuidv4()
    } else {
      this.startConversation()
    }
    document.addEventListener('click', this.handleFileClick)
  },
  computed: {
    getImage() {
      if (this.bot_id == 'preview') {
        return this.style.image
      } else {
        return this.localstyle.image
      }
    },

    getAssistantName() {
      if (this.bot_id == 'preview') {
        return this.style.assistantName
      } else {
        return this.localstyle.assistantName
      }
    },
    getHeading() {
      if (this.bot_id == 'preview') {
        return this.style.chatbotHeading
      } else {
        return this.localstyle.chatbotHeading
      }
    },
    getSubHeading() {
      if (this.bot_id == 'preview') {
        return this.style.chatbotSubHeading
      } else {
        return this.localstyle.chatbotSubHeading
      }
    },
    getHeadingStyle() {
      // console.log(
      //   'this is headerBackgroundColor' + this.style.headerBackgroundColor,
      // )
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.headerBackgroundColor,
          color: this.style.headerTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.headerBackgroundColor,
          color: this.localstyle.headerTextColor,
        }
      }
    },
    getSendButtonStyle() {
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.sendButtonBackgroundColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.sendButtonBackgroundColor,
        }
      }
    },
    getInputMessage() {
      if (this.bot_id == 'preview') {
        return this.style.inputPlaceholderText
      } else {
        return this.localstyle.inputPlaceholderText
      }
    },
    getUserMessageStyle() {
      // console.log('this is getUserMessageStyle' + this.style.userTextColor)
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.userBackgroundColor,
          color: this.style.userTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.userBackgroundColor,
          color: this.localstyle.userTextColor,
        }
      }
    },
    getBotMessageStyle() {
      // console.log('this is getBotMessageStyle' + this.style.botTextColor)

      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.botBackgroundColor,
          color: this.style.botTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.botBackgroundColor,
          color: this.localstyle.botTextColor,
        }
      }
    },
  },
  methods: {
    handleFileClick(event) {
      const link = event.target.closest('a[data-filename]')
      if (link) {
        event.preventDefault() // Prevent the default action
        const filename = link.getAttribute('data-filename')
        this.handleFileDownload(filename) // Call your download method
      }
    },
    handleFileDownload(filename) {
      console.log('Clicked', filename) // Debugging
      HTTPService.getfile(filename) // Your existing file download logic
    },
    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        const renderer = new marked.Renderer()
        renderer.link = function (href, title, text) {
          // Renderer() seems to return different parameters depending on the version of marked installed...
          const link = {
            href: href.href ?? href,
            text: href.text ?? text,
          }
          if (link.href.startsWith('/files/')) {
            // Extract the filename from the path (e.g., "/files/<filename>")
            const filename = link.href.split('/').pop()
            return `<a href="#" data-filename="${filename}">${link.text}</a>`
          } else {
            return `<a target="_blank" href="${link.href}">${link.text}</a>`
          }
        }

        text = text.replaceAll('```', '')
        let parsed = marked(text, { renderer: renderer })
        console.log('after marked.parse: ', text)

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')
        console.log('after parsed: ', parsed)

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
            'target',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },
    async startConversation() {
      try {
        const response = await HTTPService.get_conversation(this.conversationId)
        // console.log(response.data)
        for (const message of response.data) {
          const text = message.text
          const is_user = message.is_user
          this.messages.push({ text, is_user })
          // this.addMessage(message.text, message.is_user)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async sendMessage() {
      if (!this.userInput) return
      this.addMessage(this.userInput, true)
      this.messageIndex = this.messageIndex + 1
      console.log(this.user.email)
      // URL encode user input and username to ensure they are safe to include in the URL
      const encodedUserInput = encodeURIComponent(this.userInput)
      const encodedUsername = encodeURIComponent(this.user.email)

      const url = `${process.env.VUE_APP_API_URL}/conversation?bot_id=${this.bot_id}&user_input=${encodedUserInput}&conversation_id=${this.conversationId}&username=${encodedUsername}&showRagResults=true`

      this.userInput = ''
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'multipart/mixed',
          },
        })

        if (!response.ok) {
          const errorBody = await response.json()
          this.errorMessage =
            errorBody.error ||
            `HTTP error! status: ${response.status} ${response.statusText}`
          this.showErrorPopup = true
          throw new Error(this.errorMessage)
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        let buffer = ''
        let isProcessingJson = true

        // Initialize the message
        this.addMessage('', false)

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          buffer += decoder.decode(value, { stream: true })

          if (isProcessingJson) {
            const jsonEndIndex = buffer.indexOf('\r\n--frame\r\n')
            if (jsonEndIndex !== -1) {
              const jsonPart = buffer.slice(0, jsonEndIndex)
              const match = jsonPart.match(/\{[\s\S]*\}/)
              if (match) {
                this.ragResult = JSON.parse(match[0]).vdb_results
              }
              buffer = buffer.slice(jsonEndIndex + 8) // 8 is the length of '\r\n--frame'
              isProcessingJson = false
            }
          } else {
            const lines = buffer.split('\n')
            buffer = lines.pop() || '' // Keep the last incomplete line in the buffer

            for (const line of lines) {
              if (line.startsWith('data:')) {
                const data = line.slice(6)
                if (data === '[DONE]') {
                  this.messageIndex = this.messageIndex + 1
                  return // End of stream
                } else {
                  this.addMessage(data, false)
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (error.response && error.response.data.error) {
          this.errorMessage = error.response.data.error
          this.showErrorPopup = true
        } else {
          this.errorMessage = 'Failed to create chatbot: ' + error.response
          this.showErrorPopup = true
        }
      }
    },
    addMessage(text, is_user) {
      if (this.messageIndex >= 0 && is_user === false) {
        // if first message then just push
        if (!this.messages[this.messageIndex]) {
          this.messages.push({
            text: '',
            is_user: is_user,
          })
        } else {
          // console.log('adding "' + text + '"')

          this.messages[this.messageIndex].text += text
          this.messages[this.messageIndex].is_user = is_user
        }
      } else {
        this.messageIndex = this.messages.length

        this.messages.push({
          text,
          is_user,
        })
        this.messageIndex = this.messages.length - 1
      }

      // Scroll to the bottom of the chatbot conversation
      this.$refs.chatbotConversation.scrollTop =
        this.$refs.chatbotConversation.scrollHeight
    },
    endConversation() {
      // this.$router.push('/chatbot-dashboard')
    },
  },
}
</script>

<style scoped>
.chatbot {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 700px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.chatbot-conversation {
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
  overflow: auto;
}

.chatbot-message {
  margin: 10px 0;
}

.user-message {
  text-align: right;
}

.bot-message {
  text-align: left;
}

.chatbot-message div {
  display: inline-block;
  padding: 1.25rem;
  border-radius: 5px;
  max-width: 80%;
}

.chatbot-message div:last-child {
  margin-bottom: 0;
}

.chatbot-message.user-message div {
  color: #fff;
}

.chatbot-message.bot-message div {
  color: #fff;
}

.chatbot-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

input[type='text'] {
  flex: 1;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  line-height: normal;
}

button {
  padding: 12px 17px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

button:hover {
  cursor: pointer;
  background-color: #0089b9;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  background-color: rgb(173, 92, 92);
  width: 100%;
  /* height: 70px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 10px;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.chatbot-heading {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
}

p {
  margin-bottom: 0px;
  font-size: 16px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.centered-image {
  max-width: 40px;
  max-height: 40px;
}
.rag-results-table {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.rag-results-table h3 {
  margin-bottom: 10px;
}
.rag-results-table table {
  width: 100%;
  border-collapse: collapse;
}
.rag-results-table th,
.rag-results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.rag-results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.rag-results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.rag-results-table tr:hover {
  background-color: #f5f5f5;
}
</style>
